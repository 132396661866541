import { Ref, ComputedRef, computed } from 'vue'
import { Filter } from '@cubejs-client/core'
import { periodsToDates } from '@/utils/periods'

export const useTimeFilters = (
  period?: Ref<Period | undefined>,
  timeFieldName?: Ref<string | undefined>,
): [ComputedRef<Filter[]>, ComputedRef<(Date | null)[]>] => {
  const dates = computed(() => {
    if (!period) {
      return [null, new Date()]
    } else if (typeof period.value === 'string') {
      return periodsToDates(period.value)
    } else if (Array.isArray(period.value)) {
      return [period.value[0], period.value[1] || new Date()]
    }
    return [null, new Date()]
  })
  return [
    computed(() => {
      const member = timeFieldName?.value || 'AssignmentEvent.timestamp'
      const filters = [
        {
          member,
          operator: 'beforeDate',
          values: [dates.value[1]?.toString()],
        },
      ]
      dates.value[0] &&
        filters.push({
          member,
          operator: 'afterDate',
          values: [dates.value[0]?.toString()],
        })
      return filters as Filter[]
    }),
    dates,
  ]
}
