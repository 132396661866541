import {
  startOfDay,
  startOfToday,
  endOfToday,
  endOfYesterday,
  startOfYesterday,
  endOfWeek,
  startOfWeek,
  startOfMonth,
  endOfMonth,
  startOfQuarter,
  endOfQuarter,
  startOfYear,
  endOfYear,
  addDays,
  addMonths,
  addQuarters,
  addYears,
  format,
} from 'date-fns'
import { ru } from 'date-fns/locale'

export const PERIODS = [
  'today',
  'yesterday',
  'thisWeek',
  'thisMonth',
  'thisQuarter',
  'thisYear',
  'last7Days',
  'last30Days',
  'lastWeek',
  'lastMonth',
  'lastQuarter',
  'lastYear',
] as const

const PERIODS_GENERATORS = {
  today: () => [startOfToday(), endOfToday()],
  yesterday: () => [startOfYesterday(), endOfYesterday()],
  thisWeek: () => [
    startOfWeek(new Date(), { locale: ru }),
    endOfWeek(new Date(), { locale: ru }),
  ],
  thisMonth: () => [startOfMonth(new Date()), endOfMonth(new Date())],
  thisQuarter: () => [startOfQuarter(new Date()), endOfQuarter(new Date())],
  thisYear: () => [startOfYear(new Date()), endOfYear(new Date())],
  last7Days: () => [startOfDay(addDays(new Date(), -7)), new Date()],
  last30Days: () => [startOfDay(addDays(new Date(), -30)), new Date()],
  lastWeek: () => [
    startOfWeek(addDays(new Date(), -7), { locale: ru }),
    endOfWeek(addDays(new Date(), -7), { locale: ru }),
  ],
  lastMonth: () => [
    startOfMonth(addMonths(new Date(), -1)),
    endOfMonth(addMonths(new Date(), -1)),
  ],
  lastQuarter: () => [
    startOfQuarter(addQuarters(new Date(), -1)),
    endOfQuarter(addQuarters(new Date(), -1)),
  ],
  lastYear: () => [
    startOfYear(addYears(new Date(), -1)),
    endOfYear(addYears(new Date(), -1)),
  ],
} as Record<typeof PERIODS[number], () => [Date, Date]>

export const periodsToDates = (period: typeof PERIODS[number]): [Date, Date] =>
  PERIODS_GENERATORS[period]()

export const formatLocaly = (date: Date): string =>
  format(date, 'dd.MM.yy-H:mm', { locale: ru })

export const addTimeToDate = (date: Date, time: Date): Date => {
  return new Date(new Date(date)?.setHours(time.getHours(), time.getMinutes()))
}
