/* eslint-disable @typescript-eslint/no-explicit-any */
import { ref, Ref, watch, ComputedRef } from 'vue'
import cubejs, { Query, Filter } from '@cubejs-client/core'
// import { cloneDeep, isEqual } from 'lodash'
import { isEqual } from 'lodash'
import { uuid } from '@/utils/api'
// import { cubeMocks } from '@/mocks

type RequestQeary = Query[] | Query | undefined

const cubejsApi = cubejs('SECRET', {
  apiUrl: '/gateway/bi/cubejs-api/v1',
  // apiUrl: 'http://82.148.21.170:4000/cubejs-api/v1',
})

export const useCubeAPI = <T>(
  queries: Ref<RequestQeary> | ComputedRef<RequestQeary>,
  timeFilter?: Ref<Filter[]> | undefined,
): [Ref<T[] | undefined>, Ref<FetchingStatus>] => {
  const results = ref<T[]>()
  const lastQuery = ref<[typeof queries.value, Filter[] | undefined]>([
    {},
    undefined,
  ])
  const mutexKey = uuid()
  const status = ref<FetchingStatus>('init')
  const fetchFromQube = async () => {
    new Promise((resolve) => resolve(true)).then(
      () => (lastQuery.value = [queries.value, timeFilter?.value]),
    )
    if (queries.value === undefined) {
      results.value = []
      status.value = 'empty'
      return
    }
    if (
      isEqual(queries.value, lastQuery.value[0]) &&
      isEqual(timeFilter?.value, lastQuery.value[1])
    ) {
      return
    }
    status.value = 'loading'
    try {
      const result = (await cubejsApi.load(
        {
          ...queries.value,
          filters: [
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ...(queries.value.filters || []),
            ...(timeFilter?.value ? [...timeFilter.value] : []),
          ],
        },
        { mutexKey, mutexObj: {} },
      )) as any
      results.value = result.loadResponses?.[0]?.data
      if (results.value?.length) {
        status.value = 'complete'
      } else {
        status.value = 'empty'
      }
    } catch (error) {
      console.error(error)
      status.value = 'error'
    }

    // console.log(
    //   `'${JSON.stringify(queries.value)}': ${JSON.stringify(results.value)}`,
    // )

    // const keys = cloneDeep(queries.value)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // delete keys?.timeDimensions
    // results.value = cubeMocks[JSON.stringify(keys)] || []
    // status.value = 'complete'
  }
  watch(
    [queries, timeFilter].filter((e) => e),
    fetchFromQube,
    { immediate: true },
  )

  // watch(results, (val) =>
  //   console.log(
  //     `'${JSON.stringify(queries.value)}':`,
  //     JSON.stringify(val) + ',',
  //   ),
  // )
  return [results, status]
}
