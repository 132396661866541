import { Filter, Query } from '@cubejs-client/core'
import { ComputedRef, Ref, computed } from 'vue'
import { FilterBase } from '../filters'
import { useCubeAPI } from './useCubeAPI'

export const useMainDirection = (
  measureField: Ref<string>,
  currentLeftQuery: ComputedRef<FilterBase | undefined>,
  timeFilter: ComputedRef<Filter[]>,
): [directions: ComputedRef<TopBlockAmounts>, status: Ref<FetchingStatus>] => {
  const directionQuery = computed(() => {
    return {
      measures: [measureField.value],
      dimensions: ['VehicleTransitStatistics.mainDirection'],
      filters: [
        ...(currentLeftQuery.value?.cubeFilters?.filters
          ? currentLeftQuery.value.cubeFilters.filters
          : []),
      ],
    } as Query
  })
  const [directionsFromCube, status] = useCubeAPI<{
    'VehicleTransitStatistics.mainDirection': string
    [key: string]: string
  }>(directionQuery, timeFilter)

  const directions = computed<TopBlockAmounts>(() => {
    const directionsAmounts = { entry: 0, exit: 0 } as TopBlockAmounts
    const dataTransform =
      currentLeftQuery.value?.dataTransform || ((val) => val)
    // eslint-disable-next-line no-unused-expressions
    directionsFromCube.value?.forEach((direction) => {
      direction['VehicleTransitStatistics.mainDirection'] ===
        (currentLeftQuery.value?.entryFieldName || 'In') &&
        (directionsAmounts.entry = dataTransform(direction[measureField.value]))
      direction['VehicleTransitStatistics.mainDirection'] ===
        (currentLeftQuery.value?.exitFieldName || 'Out') &&
        (directionsAmounts.exit = dataTransform(direction[measureField.value]))
    })
    return directionsAmounts
  })
  return [directions, status]
}
