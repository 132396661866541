export const RUSSIAN_TRANSLATIONS = {
  dashboardTitle: 'МАПП Торфяновка',
  turnedAround: 'Развернулись',
  avgTotalTime: 'Среднее время',
  statusDiagramHeader:
    'Схема установки камер аналитической системы контроля трафика МАПП Торфяновка',
  entry: 'ВЪЕЗД В РФ',
  exit: 'ВЫЕЗД ИЗ РФ',
  popsUnprovided: 'Не указан виджет в настройках',
  allRegions: 'Все',
  passage: 'Проехали',
  chart: 'График',
  table: 'Таблица',
  search: 'Поиск',
  cameras: 'Камеры',
  events: 'События',
  passages: 'Проезды',
  wrongFormat: 'Неверный формат',
  selectPeriod: 'Выберите период',
  timeChart: 'Временный график',
  all: 'Все',
  innerObjects: 'Внутренние объекты',
  empty: 'Пусто',
  error: {
    fetchError: 'Не удалось получить объект',
  },
  CarIn: 'Въехало легковых в Россию',
  CarOut: 'Выехало легковых из России',
  TruckOut: 'Выехало грузовых из России',
  TruckIn: 'Въехало грузовых в Россию',
  CarReverseIn: 'Развернулось легковых в Россию',
  CarReverseOut: 'Развернулось легковых в Финляндию',
  TruckReverseIn: 'Развернулось грузовых в Россию',
  TruckReverseOut: 'Развернулось грузовых в Финляндию',
  granularity: {
    second: 'Секунды',
    minute: 'Минуты',
    '5min': '5 минут',
    '15min': '15 минут',
    '30min': '30 минут',
    hour: 'Часы',
    day: 'Дни',
    week: 'Недели',
    month: 'Месяца',
    quarter: 'Кварталы',
    year: 'Годы',
  },
  for: 'до',
  periods: {
    allTime: 'За все время',
    today: 'Сегодня',
    yesterday: 'Вчера',
    thisWeek: 'Эта неделя',
    thisMonth: 'Этот месяц',
    thisQuarter: 'Этот квартал',
    thisYear: 'Этот год',
    last7Days: 'Последние 7 дней',
    last30Days: 'Последние 30 дней',
    lastWeek: 'Предыдущая неделя',
    lastMonth: 'Предыдущий месяц',
    lastQuarter: 'Предыдущий квартал',
    lastYear: 'Предыдущий год',
  },
}
