import { useI18n, Path } from 'vue-i18n'

const capitalize = (str: string) => {
  return typeof str === 'string' && str !== ''
    ? str.replace(/^\w/, (c) => c.toLocaleUpperCase())
    : ''
}
export const useTe = (): ((path: Path, label?: string) => string) => {
  const { te: $te, t } = useI18n()
  return (path: Path, label?: string) => {
    return $te(path) ? t(path) : capitalize(label || path)
  }
}
