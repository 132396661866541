import { watch, Ref, ComputedRef } from 'vue'

export const useObserver = <
  T extends Record<string | number, unknown> | Record<string, unknown>[],
  G extends Ref<T> | ComputedRef<T>,
>(
  data: G,
): ((callback: (newData: T) => void) => () => void) => {
  const subscribers = new Set() as Set<(newData: T) => void>
  const subscribe = (callback: (newData: T) => void) => {
    callback(data.value)
    subscribers.add(callback)
    return () => {
      subscribers.delete(callback)
    }
  }
  const notify = (newData: T) =>
    subscribers.forEach((subscriber) => {
      subscriber(newData)
    })

  watch(data, (val) => notify(val))

  return subscribe
}
