export const daysOfAllowedGranularities = new Map([
  [0, ['second', 'minute', '5min', '15min', '30min', 'hour']],
  [7, ['5min', '15min', '30min', 'hour', 'day']],
  [30, ['30min', 'hour', 'day', 'week']],
  [90, ['day', 'week', 'month', 'quarter']],
  [182, ['month', 'quarter']],
  [365, ['month', 'quarter', 'year']],
]) as Map<number, Granularity[]>

export const granularityToMilisecondMap: {
  [key in Granularity]: number | 'month' | 'quarter' | 'week'
} = {
  second: 1000,
  minute: 60000,
  '5min': 300000,
  '15min': 900000,
  '30min': 1800000,
  hour: 3600000,
  day: 86400000,
  week: 'week',
  month: 'month',
  quarter: 'quarter',
  year: 31536000000,
}
