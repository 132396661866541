import { Query } from '@cubejs-client/core'

type Locales = {
  [index: string]: string | Locales
}

export type TableSchema = {
  locales?: Locales
  viewTable?: {
    order: string[]
    columns: {
      [index: string]: {
        cell: {
          type: string
          entity?: string
          format?: {
            key: string
            config?: unknown
          }
          isDetailed?: boolean
          localization?: { path: string }
          idField?: string
        }
        filter?: {
          type: string
          range?: [number, number]
          step?: number
        }
        sortable?: string
        hidden?: boolean
      }
    }
  }
  entityType?: string
}

export type FilterBase = {
  name: string
  color: string
  cubeFilters: Query
  additionalQueryForDiagram?: {
    cube: Query
    timeFieldName?: string
    timeChartDimension?: string
  }
  timeChartDimension?: string
  isForAllTime?: boolean
  icon: {
    iconClass: string
  }
  dataTransform?: (value: string | number) => string | number
  legend?: Record<string, string>
  caption: string
  valueTransform?: (value: string) => string
  chartTransform?: (value: string) => number
  chartCaption?: string
  entryFieldName?: string
  exitFieldName?: string
  timeFieldName?: string
  tableSchema?: TableSchema
}

export const filters: FilterBase[] = [
  {
    name: 'passage',
    color: 'var(--text-color-secondary)',
    icon: {
      iconClass: 'mdi-car-arrow-right',
    },
    cubeFilters: {
      measures: ['VehicleTransitStatistics.count'],
      filters: [
        {
          member: 'VehicleTransitStatistics.mainDirection',
          operator: 'equals',
          values: ['In', 'Out'],
        },
      ],
    },
    additionalQueryForDiagram: {
      cube: {
        measures: ['VehicleTransit.count'],
        dimensions: ['VehicleTransit.routePointId'],
      },
      timeFieldName: 'VehicleTransit.timestamp',
    },
    caption: 'шт.',
    tableSchema: {
      locales: {
        ru: {
          time: 'Время',
          count: 'Событий',
        },
      },
      viewTable: {
        order: ['time', 'count'],
        columns: {
          time: {
            cell: {
              type: 'text',
            },
          },
          count: {
            cell: {
              type: 'text',
            },
          },
        },
      },
    },
    legend: {
      TruckOut: 'rgba(0, 240, 255, 1)',
      CarIn: 'rgba(241, 158, 61, 1)',
      TruckIn: 'rgba(235, 255, 0, 1)',
      CarOut: 'rgba(163, 120, 255, 1)',
    },
    timeFieldName: 'VehicleTransitStatistics.startedAt',
    timeChartDimension: 'VehicleTransitStatistics.direction',
  },
  {
    name: 'avgTotalTime',
    color: 'var(--success-color)',
    icon: {
      iconClass: 'mdi-clock-time-eleven',
    },
    cubeFilters: {
      measures: ['VehicleTransitStatistics.avgTotal'],
      filters: [
        {
          member: 'VehicleTransitStatistics.direction',
          operator: 'equals',
          values: ['TruckOut', 'CarIn', 'TruckIn', 'CarOut'],
        },
      ],
    },
    caption: '',
    legend: {
      TruckOut: 'rgba(0, 240, 255, 1)',
      CarIn: 'rgba(241, 158, 61, 1)',
      TruckIn: 'rgba(235, 255, 0, 1)',
      CarOut: 'rgba(163, 120, 255, 1)',
    },
    timeFieldName: 'VehicleTransitStatistics.startedAt',
    timeChartDimension: 'VehicleTransitStatistics.direction',
    tableSchema: {
      locales: {
        ru: {
          time: 'Время',
          'Грузовой транспорт': 'Грузовой транспорт',
          'Легковой транспорт': 'Легковой транспорт',
          count: 'Все',
        },
      },
      viewTable: {
        order: ['time', 'Легковой транспорт', 'Грузовой транспорт', 'count'],
        columns: {
          time: {
            cell: {
              type: 'text',
            },
          },
          'Легковой транспорт': {
            cell: {
              type: 'text',
            },
          },
          'Грузовой транспорт': {
            cell: {
              type: 'text',
            },
          },
          count: {
            cell: {
              type: 'text',
            },
          },
        },
      },
    },
    chartTransform(value: string): number {
      const valueNumber = Number(value)
      if (!valueNumber) return 0
      return Number((valueNumber / 60 / 60).toFixed(2))
    },
    chartCaption: 'в часах',
    dataTransform: (val: string | number): string | number => {
      const valNumber = Number(val)
      if (!valNumber) return val
      const minutes = Math.floor(Number(val) / 60)
      const restSeconds = Math.floor(Number(val) % 60)
      return minutes ? `${minutes}м ${restSeconds} с` : `${restSeconds} с`
    },
  },
  {
    name: 'turnedAround',
    color: 'var(--error-color)',
    icon: {
      iconClass: 'mdi-car-off',
    },
    entryFieldName: 'ReverseIn',
    exitFieldName: 'ReverseOut',
    cubeFilters: {
      measures: ['VehicleTransitStatistics.count'],
      filters: [
        {
          member: 'VehicleTransitStatistics.mainDirection',
          operator: 'equals',
          // values: ['Reverse'],
          values: ['ReverseIn', 'ReverseOut'],
        },
      ],
    },
    legend: {
      CarReverseIn: 'rgba(0, 240, 255, 1)',
      CarReverseOut: 'rgba(241, 158, 61, 1)',
      TruckReverseOut: 'rgba(235, 255, 0, 1)',
      TruckReverseIn: 'rgba(163, 120, 255, 1)',
    },
    timeFieldName: 'VehicleTransitStatistics.startedAt',
    timeChartDimension: 'VehicleTransitStatistics.direction',
    caption: 'шт.',
  },
]
