/* eslint-disable no-unused-expressions */
import { Ref, ComputedRef, computed } from 'vue'
import { useCubeAPI } from './useCubeAPI'
import { FilterBase } from '../filters'
import { startOfYear } from 'date-fns'
import { Query } from '@cubejs-client/core'

export const useGranulatedData = (
  measureField: Ref<string>,
  currentLeftQuery: ComputedRef<FilterBase | undefined>,
  dateRange: ComputedRef<(Date | null)[]>,
  currentGranularity: Ref<Granularity>,
  // currentTopFilter: Ref<HistoryValue>,
): [
  Ref<
    | {
        [k: string]: string
        'VehicleTransitStatistics.count': string
      }[]
    | undefined
  >,
  Ref<FetchingStatus>,
] => {
  const dimensionField = computed(
    () =>
      currentLeftQuery.value?.timeFieldName ||
      'VehicleTransitStatistics.startedAt',
  )

  const granulatedQuery = computed(() => {
    return {
      measures: [measureField.value],
      timeDimensions: [
        {
          dimension: dimensionField.value,
          dateRange: [
            dateRange.value[0]
              ? dateRange.value[0].toString()
              : startOfYear(new Date()).toString(),
            dateRange.value[1]
              ? dateRange.value[1].toString()
              : new Date().toString(),
          ],
          granularity: currentGranularity.value,
        },
      ],
      dimensions: [
        ...(currentLeftQuery.value?.timeChartDimension
          ? [currentLeftQuery.value?.timeChartDimension]
          : []),
      ],
      filters: [
        ...(currentLeftQuery.value?.cubeFilters?.filters
          ? currentLeftQuery.value.cubeFilters.filters
          : []),
      ],
    } as Query
  })
  const [timeChartData, timeChartStatus] = useCubeAPI<{
    'VehicleTransitStatistics.count': string
    [k: string]: string
  }>(granulatedQuery)

  const granulatedData = computed(() => {
    return timeChartData?.value
  })
  return [granulatedData, timeChartStatus]
}
