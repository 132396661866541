import { Ref, ComputedRef, computed, ref, watch } from 'vue'
import { useCubeAPI } from './useCubeAPI'
import { Filter, Query } from '@cubejs-client/core'
import { normalizeEntities } from '@/utils/api'

/**
 * Build composited tree from all objects into node objects (cameras in complex object for example)
 * @param listOfEntities array of all entities
 */
const makeObjectsTree = (
  listOfEntities: IEntity[],
): Map<string | null, EntityNode> => {
  const mapOfEntities = new Map(
    listOfEntities.map((entity) => [
      entity.id,
      entity as CompositionEntity & IMapEntity,
    ]),
  )
  const KPMap = new Map(
    listOfEntities.map(({ id, title, amount }) => [
      id,
      { id, title, amount } as EntityNode,
    ]),
  )
  // put childrens in parents back (=
  mapOfEntities.forEach((entity) => {
    const nodeEntity = KPMap.get(entity.servicePath)
    const leafEntity = KPMap.get(entity.id)
    if (leafEntity) {
      const childrens = [...(nodeEntity?.childrens || []), leafEntity]
      if (nodeEntity) {
        nodeEntity.childrens = childrens
      } else {
        KPMap.set(entity.servicePath, {
          id: entity.servicePath,
          childrens,
        } as EntityNode)
      }
    }
  })
  // clear all not root objects, they don't need there
  return new Map(
    [...KPMap.entries()].filter(([_, entity]) => entity?.childrens),
  )
}

export const useEntitiesTree = (
  measureField: Ref<string>,
  timeFilter: ComputedRef<Filter[]>,
  currentTopFilter:
    | Ref<string | undefined | null>
    | ComputedRef<string | undefined | null>,
): [Ref<EntityNode[] | []>, Ref<FetchingStatus>] => {
  const camerasQuery = computed(() => {
    return ['TransitStatistics.count', 'Crossroad.count'].includes(
      measureField.value,
    ) && currentTopFilter.value
      ? ({
          measures: [measureField.value],
          dimensions: ['Camera.id', 'Camera.title', 'ComplexObject.id'],
          filters: [
            {
              member: 'ComplexObject.id',
              operator: 'equals',
              values: [currentTopFilter.value],
            },
          ],
        } as Query)
      : undefined
  })
  const [camerasFromCube, camerasStatus] = useCubeAPI<{
    'Camera.id': string
    'Camera.title': string
    'Camera.servicePath': string
    [key: string]: string
  }>(camerasQuery, timeFilter)

  const entitiesFromCube = computed<IEntity[]>(() => {
    return [
      ...normalizeEntities(
        'Camera',
        measureField.value,
        camerasFromCube.value || [],
      ),
    ]
  })

  const entitiesTree = computed(() => {
    return makeObjectsTree(entitiesFromCube.value)
  })

  const currentChildrens = ref<EntityNode[]>([])
  watch([entitiesTree, currentTopFilter], () => {
    currentChildrens.value =
      (currentTopFilter.value !== undefined &&
        entitiesTree.value
          ?.get(currentTopFilter.value)
          ?.childrens?.map((entity) => ({ ...entity, showRating: true }))) ||
      ([] as EntityNode[])
  })
  return [currentChildrens, camerasStatus]
}
