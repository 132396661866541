import { SearchParams, history } from '@netvision/lib-history'
import { onUnmounted, ref, Ref } from 'vue'

export const useHistoryJs = (
  fieldName: string,
): [valueRef: Ref<HistoryValue>, setValue: (val?: HistoryValue) => void] => {
  const fieldValue = SearchParams.parse(location.search)[fieldName]
  const valueRef = ref<HistoryValue>(
    fieldValue !== undefined ? (fieldValue as string | null) : undefined,
  )
  const setValue = (id?: HistoryValue) => {
    if (valueRef.value === id || id === undefined) {
      const query = { ...SearchParams.parse(history.location.search) }
      delete query[fieldName]
      history.push({
        search: SearchParams.stringify(query),
      })
    } else {
      const query = SearchParams.parse(history.location.search)
      history.push({
        search: SearchParams.stringify({
          ...query,
          [fieldName]: id,
        }),
      })
    }
  }
  const unsubsсribeHistory = history.listen((location) => {
    const fieldValue = SearchParams.parse(location.search)[fieldName]
    if (fieldValue !== undefined) {
      valueRef.value = fieldValue as string | null
    } else {
      valueRef.value = undefined
    }
  })
  onUnmounted(unsubsсribeHistory)
  return [valueRef, setValue]
}
