import { ComputedRef, Ref, computed, ref, watch } from 'vue'
import {
  daysOfAllowedGranularities,
  granularityToMilisecondMap,
} from '../GranularitiesMaps'
import { format } from 'date-fns'

const startsOfQuarters = [
  new Date(new Date().getFullYear(), 0, 1),
  new Date(new Date().getFullYear(), 3, 1),
  new Date(new Date().getFullYear(), 6, 1),
  new Date(new Date().getFullYear(), 9, 1),
]
function generateISODateArray(
  startDate: Date,
  endDate: Date,
  interval: number | 'month' | 'quarter' | 'week',
): string[] {
  const isoDates: string[] = []

  let currentDate = new Date(startDate)
  while (currentDate <= endDate) {
    isoDates.push(format(currentDate, "yyyy-MM-dd'T'HH:mm:ss.SSS"))
    if (typeof interval === 'number') {
      currentDate = new Date(currentDate.getTime() + interval)
    } else if (interval === 'week') {
      currentDate.setDate(currentDate.getDate() - currentDate.getDay() + 1)
      currentDate = new Date(currentDate.setDate(currentDate.getDate() + 7))
    } else if (interval === 'month') {
      currentDate = new Date(currentDate.setMonth(currentDate.getMonth() + 1))
    } else if (interval === 'quarter') {
      // next quarter firest day
      const nextQuarterDay = startsOfQuarters.find(
        (date) => date.getTime() > currentDate.getTime(),
      )
      if (nextQuarterDay) {
        currentDate = new Date(nextQuarterDay)
      } else {
        break
      }
    }
  }
  return isoDates
}

const CUSTOM_GRANULARITIES = ['5min', '15min', '30min']
const MEASURE_FIELDS_WITH_CUSTOM_GRANULARITY = ['Crossroad.count']

const dateRangeToDays = (date1: Date | null, date2: Date | null) => {
  if (!date1 || !date2) return 0
  return (date2.getTime() - date1.getTime()) / (1000 * 60 * 60 * 24)
}

export const useGranularity = (
  measureField: Ref<string>,
  dateRange: ComputedRef<(Date | null)[]>,
): [
  currentGranularity: Ref<Granularity>,
  listOfAllowedGranularities: ComputedRef<Granularity[]>,
  setGranularity: (granularity: Granularity) => void,
  dateRangeWithInterval: ComputedRef<string[]>,
] => {
  const isMeasureFieldWithCustomGranularity = computed(() => {
    return MEASURE_FIELDS_WITH_CUSTOM_GRANULARITY.includes(measureField.value)
  })
  const cleraFromCustomGranularity = (granularities: Granularity[]) => {
    return isMeasureFieldWithCustomGranularity.value
      ? granularities
      : granularities.filter(
          (granularity) => !CUSTOM_GRANULARITIES.includes(granularity),
        )
  }
  const currentDaterangeDays = computed(() =>
    dateRangeToDays(dateRange.value[0], dateRange.value[1]),
  )
  const listOfAllowedGranularities = computed(() => {
    let lastGranularities = [] as Granularity[]
    for (const [granularityKey, granularities] of daysOfAllowedGranularities) {
      if (granularityKey >= currentDaterangeDays.value) {
        return lastGranularities
      } else {
        lastGranularities = cleraFromCustomGranularity(granularities)
      }
    }
    return lastGranularities
  })
  const granularity = ref(listOfAllowedGranularities.value[0])

  const dateRangeWithInterval = computed(() => {
    if (!dateRange.value[0] || !dateRange.value[1]) return []
    return generateISODateArray(
      new Date(dateRange.value[0]),
      new Date(dateRange.value[1]),
      granularityToMilisecondMap[granularity.value] || 0,
    )
  })

  watch([currentDaterangeDays, measureField], () => {
    !listOfAllowedGranularities.value.includes(granularity.value) &&
      (granularity.value = cleraFromCustomGranularity(
        listOfAllowedGranularities.value,
      )[0])
  })

  const setGranularity = (newGranularity: Granularity) => {
    listOfAllowedGranularities.value.includes(newGranularity) &&
      (granularity.value = newGranularity)
  }
  return [
    granularity,
    listOfAllowedGranularities,
    setGranularity,
    dateRangeWithInterval,
  ]
}
