import { repositoryGetter, TUnionRepo } from '@netvision/lib-api-repo'
import { concat } from 'lodash'

export const uuid = (): string => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0
    const v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

const timers = {} as Record<string, number>
export const debounce = (
  // eslint-disable-next-line @typescript-eslint/ban-types
  callback: Function,
  timeout: number,
  key = 'main',
): void => {
  clearTimeout(timers[key])
  timers[key] = setTimeout(callback, timeout)
}

export const normalizeEntities = (
  type: string,
  measureField: string,
  entities: Record<string, string>[],
  servicePathField = 'ComplexObject.id',
): IEntity[] =>
  entities.map(
    (entity) =>
      Object.entries(entity).reduce((acc, [key, val]) => {
        if (key.includes(type)) {
          acc[key.replace(`${type}.`, '')] = val
        } else if (key === measureField) {
          acc.amount = val
        } else if (key === servicePathField) {
          acc.servicePath = val
        } else {
          acc[key] = val
        }
        return acc
      }, {} as Record<string, string>) as IEntity,
  )

export const api = repositoryGetter({ name: 'lib-api' }) as TUnionRepo

export const resolveOverallStatus = (
  statuses: FetchingStatus[],
): FetchingStatus => {
  if (statuses.includes('error')) {
    return 'error'
  } else if (statuses.includes('loading')) {
    return 'loading'
  } else if (statuses.every((status) => status === 'complete')) {
    return 'complete'
  }
  return 'init'
}
export const listAllEntities = async <T extends IEntity>(
  options: Parameters<typeof api.getEntitiesList>[0],
): Promise<T[]> => {
  const fetcher = (offset: number) =>
    api.getEntitiesList<T>({
      limiter: {
        ...options.limiter,
        keyValues: true,
        limit: 1000,
        offset,
      },
      filter: options.filter ? options.filter : undefined,
    })
  let offset = 0
  let count = 0
  let entities = [] as T[]
  let results = [] as T[]
  while (offset <= count) {
    // eslint-disable-next-line @typescript-eslint/no-extra-semi
    ;({ results } = await fetcher(offset))
    count += results.length
    offset += 1000
    entities = concat(entities, results)
  }
  return entities
}
