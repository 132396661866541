/* eslint-disable no-unused-expressions */
import { Ref, ComputedRef, computed } from 'vue'
import { FilterBase } from '../filters'
import { parseJSON, format } from 'date-fns'
import { ru } from 'date-fns/locale'

const GRANULARITY_TIME_MAP = {
  day: 'd MMM yy',
  minute: 'kk:mm dd.MM',
  hour: 'kk:mm dd.MM',
  '30min': 'kk:mm dd.MM',
  '15min': 'kk:mm dd.MM',
  '5min': 'kk:mm dd.MM',
} as Record<string, string>

export const useTimeChart = (
  timeChartData: Ref<
    | {
        [k: string]: string
      }[]
    | undefined
  >,
  measureField: Ref<string>,
  currentLeftQuery: ComputedRef<FilterBase | undefined>,
  currentGranularity: Ref<Granularity>,
  dateRangeWithInterval: ComputedRef<string[]>,
): ComputedRef<ChartWidgetSettings> => {
  const type = 'line' as const
  const dimensionField = computed(
    () =>
      currentLeftQuery.value?.timeFieldName ||
      'VehicleTransitStatistics.startedAt',
  )
  const chartData = computed(() => {
    if (!timeChartData?.value) {
      return {
        xAxis: {
          data: [],
        },
        yAxis: {
          series: [],
        },
      }
    }
    const timeDimensionsVariants = new Set() as Set<string>
    const timelines = new Map() as Map<string, Record<string, number>>

    timeChartData.value?.forEach((data) => {
      const dimensionFieldValue =
        data?.[currentLeftQuery.value?.timeChartDimension || ''] || 'base'
      timeDimensionsVariants.add(dimensionFieldValue)

      const dateField =
        data[`${dimensionField.value}.${currentGranularity.value}`]

      const chartTransform =
        currentLeftQuery.value?.chartTransform ||
        ((val: string) => Math.floor(Number(val)))
      dateField &&
        timelines.set(dateField, {
          ...(timelines.get(dateField) || {}),
          [dimensionFieldValue]: chartTransform(data[measureField.value]),
        })
    })
    const xAxis = {
      data: dateRangeWithInterval.value.map((date) => {
        try {
          return format(
            parseJSON(date),
            GRANULARITY_TIME_MAP[currentGranularity.value] || 'd MMM yy',
            { locale: ru },
          )
        } catch (error) {
          return date
        }
      }),
    } as ChartWidgetSettings['xAxis']

    const yAxis = {
      series: Array.from(timeDimensionsVariants.values()).map((id) => ({
        id,
        type,
        styles: {
          itemStyle: {
            color: currentLeftQuery.value?.legend?.[id] || '#3C72FF',
          },
          backgroundLineColor:
            currentLeftQuery.value?.legend?.[id] || '#3C72FF',
        },
        data: dateRangeWithInterval.value.map(
          (date) => timelines.get(date)?.[id] || 0,
        ),
      })),
    } as ChartWidgetSettings['yAxis']
    return { xAxis, yAxis }
  })
  return chartData
}
