/* eslint-disable no-unused-expressions */
import { Ref, ComputedRef, computed } from 'vue'
import { FilterBase } from '../filters'
import { parseJSON, format } from 'date-fns'
import { ru } from 'date-fns/locale'

const DIMENTION_MAP = {
  'CrossroadAvgDelayByDayOnCrossroad.avg':
    'CrossroadAvgDelayByDayOnCrossroad.windowStart',
  'CrossroadAvgDelayByHourOnCrossroad.avg':
    'CrossroadAvgDelayByHourOnCrossroad.windowStart',
  'TransitStatistics.count': 'AssignmentEvent.timestamp',
  'Crossroad.count': 'Crossroad.startedAt',
} as Record<string, string>

const GRANULARITY_TIME_MAP = {
  day: 'd MMM yy',
  minute: 'kk:mm dd.MM',
  hour: 'kk:mm dd.MM',
  '30min': 'kk:mm dd.MM',
  '15min': 'kk:mm dd.MM',
  '5min': 'kk:mm dd.MM',
} as Record<string, string>

export const useTableData = (
  granulatedData: Ref<
    | {
        [k: string]: string
      }[]
    | undefined
  >,
  measureField: Ref<string>,
  currentLeftQuery: ComputedRef<FilterBase | undefined>,
  currentGranularity: Ref<Granularity>,
  dateRangeWithInterval: ComputedRef<string[]>,
): ComputedRef<Record<string, unknown>[]> => {
  const dimensionField = computed(
    () => DIMENTION_MAP[measureField.value] || 'AssignmentEvent.timestamp',
  )
  const tableData = computed(() => {
    const timeDimensionsVariants = new Set() as Set<string>
    const timelines = new Map() as Map<string, Record<string, string>>

    granulatedData.value?.forEach((data) => {
      const dimensionFieldValue =
        data?.[currentLeftQuery.value?.timeChartDimension || ''] || 'count'
      timeDimensionsVariants.add(dimensionFieldValue)

      const dateField =
        data[`${dimensionField.value}.${currentGranularity.value}`]
      dateField &&
        timelines.set(dateField, {
          ...(timelines.get(dateField) || {}),
          [dimensionFieldValue]: data[measureField.value],
        })
    })

    const data = dateRangeWithInterval.value.map((date) => {
      try {
        return {
          time: format(
            parseJSON(date),
            GRANULARITY_TIME_MAP[currentGranularity.value] || 'd MMM yy',
            { locale: ru },
          ),
          ...(timelines.get(date) || { count: 0 }),
        }
      } catch (error) {
        return {}
      }
    })
    return data
  })
  return tableData
}
