(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@netvision/lib-history"), require("@netvision/lib-api-repo"));
	else if(typeof define === 'function' && define.amd)
		define(["@netvision/lib-history", "@netvision/lib-api-repo"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("@netvision/lib-history"), require("@netvision/lib-api-repo")) : factory(root["@netvision/lib-history"], root["@netvision/lib-api-repo"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(window, function(__WEBPACK_EXTERNAL_MODULE__4ebd__, __WEBPACK_EXTERNAL_MODULE__9463__) {
return 