/* eslint-disable no-unused-expressions */
import { Ref, ComputedRef, computed } from 'vue'
import { useCubeAPI } from './useCubeAPI'
import { FilterBase } from '../filters'
import { Filter, Query } from '@cubejs-client/core'
import { useTimeFilters } from './useTimeFilters'

export const usePointsTransit = (
  measureField: Ref<string>,
  timeFilter: ComputedRef<Filter[]>,
  currentLeftQuery: ComputedRef<FilterBase | undefined>,
  dates: Ref<Period | undefined>,
): [
  pointsTransitAmounts: ComputedRef<{ id: string; value: number | string }[]>,
  status: Ref<FetchingStatus>,
] => {
  const dimension = computed(() => {
    return (
      currentLeftQuery.value?.timeChartDimension ||
      'VehicleTransitStatistics.direction'
    )
  })
  const pointsTransitQuery = computed(() => {
    return {
      measures: [measureField.value],
      dimensions: [dimension.value],
      filters: [
        ...(currentLeftQuery.value?.cubeFilters?.filters
          ? currentLeftQuery.value.cubeFilters.filters
          : []),
      ],
    } as Query
  })
  const currentAddtitonalTimeField = computed(
    () => currentLeftQuery.value?.additionalQueryForDiagram?.timeFieldName,
  )
  const [additionaltimeFilter, ..._] = useTimeFilters(
    dates,
    currentAddtitonalTimeField,
  )
  const additionaTransitQuery = computed(() => {
    const additionalQueryForDiagram =
      currentLeftQuery.value?.additionalQueryForDiagram
    return additionalQueryForDiagram
      ? {
          ...additionalQueryForDiagram.cube,
        }
      : undefined
  })
  const [additionalTransitFromCube, additionalTransitStatus] = useCubeAPI<{
    [key: string]: string
  }>(additionaTransitQuery, additionaltimeFilter)
  const [pointsTransitFromCube, pointsTransitStatus] = useCubeAPI<{
    [key: string]: string
  }>(pointsTransitQuery, timeFilter)

  const pointsTransitAmounts = computed<
    { id: string; value: number | string }[]
  >(() => {
    const dataTransform =
      currentLeftQuery.value?.dataTransform || ((val) => val)
    return [
      ...(pointsTransitFromCube.value
        ? pointsTransitFromCube.value?.map((point) => ({
            id: point[dimension.value],
            value: dataTransform(point[measureField.value] || 0),
          }))
        : []),
      ...(additionalTransitFromCube.value
        ? additionalTransitFromCube.value?.map((point) => ({
            id: point[
              currentLeftQuery.value?.additionalQueryForDiagram?.cube
                ?.dimensions?.[0] || 'VehicleTransit.routePointId'
            ],
            value:
              point[
                currentLeftQuery.value?.additionalQueryForDiagram?.cube
                  ?.measures?.[0] || measureField.value
              ] || '0',
          }))
        : []),
    ]
  })

  return [pointsTransitAmounts, pointsTransitStatus]
}
